interface IconsProps {
  height?: number;
  width?: number;
}

const Manufacturing = (props: IconsProps) => {
  const { height, width } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m16.942 11.165.387.673c.116.2.282.36.48.463.197.103.417.142.632.114.216-.029.418-.124.582-.274.164-.15.283-.349.342-.572l.2-.75c.072-.266.22-.5.425-.667.205-.169.456-.262.717-.267l.73-.012c.218-.004.429-.076.607-.206s.316-.314.397-.529a1.28 1.28 0 0 0-.235-1.294l-.51-.573a1.393 1.393 0 0 1-.333-.746c-.037-.278.01-.559.132-.804l.343-.685a1.28 1.28 0 0 0-.108-1.311 1.162 1.162 0 0 0-.521-.408 1.056 1.056 0 0 0-.639-.042l-.709.178c-.253.063-.52.038-.762-.072a1.296 1.296 0 0 1-.583-.537l-.39-.672a1.188 1.188 0 0 0-.48-.464 1.067 1.067 0 0 0-.632-.113 1.067 1.067 0 0 0-.581.273c-.165.15-.284.349-.343.572l-.2.75c-.071.267-.22.502-.424.67a1.165 1.165 0 0 1-.718.267l-.732.013a1.056 1.056 0 0 0-.607.206c-.178.13-.316.314-.397.529a1.28 1.28 0 0 0 .236 1.294l.509.573c.181.205.299.464.335.742.037.278-.009.56-.131.804l-.344.686a1.279 1.279 0 0 0 .108 1.311c.135.186.316.327.522.407.205.08.427.094.638.042l.71-.177c.252-.063.52-.038.761.071.242.11.447.297.586.537Z"
      />
      <circle
        cx="17.728"
        cy="7.005"
        r=".919"
        fill="currentColor"
        transform="rotate(-7.514 17.728 7.005)"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m5.084 19.668.087.9c.026.268.124.52.284.724.16.204.375.351.617.425.242.074.503.07.75-.01.246-.08.468-.233.639-.44l.574-.7a1.51 1.51 0 0 1 .773-.504c.3-.08.61-.057.89.064l.781.34c.233.1.491.126.743.073.252-.052.487-.18.677-.37a1.49 1.49 0 0 0 .375-1.486l-.264-.854a1.625 1.625 0 0 1 .006-.953c.095-.312.28-.589.528-.79l.695-.561a1.491 1.491 0 0 0 .518-1.444 1.356 1.356 0 0 0-.356-.684 1.231 1.231 0 0 0-.658-.352l-.838-.155c-.299-.055-.57-.21-.774-.443a1.512 1.512 0 0 1-.36-.852l-.089-.902a1.386 1.386 0 0 0-.285-.723 1.244 1.244 0 0 0-.616-.426 1.245 1.245 0 0 0-.75.01c-.246.08-.468.233-.64.441l-.574.7c-.204.249-.475.426-.773.507-.3.079-.611.057-.89-.064l-.784-.34a1.23 1.23 0 0 0-.743-.074c-.252.052-.487.18-.677.37a1.492 1.492 0 0 0-.375 1.486l.264.854c.094.305.093.638-.002.95-.095.313-.28.59-.528.79l-.695.562a1.492 1.492 0 0 0-.518 1.443c.052.263.176.5.356.684.18.184.408.307.657.353l.838.154c.3.056.57.21.775.444.204.232.33.531.362.853Z"
      />
      <circle
        cx="7.927"
        cy="15.631"
        r="1.2"
        fill="currentColor"
        transform="rotate(16.945 7.927 15.631)"
      />
    </svg>
  );
};

export default Manufacturing;
